import { Link } from "react-router-dom";
import React from 'react';


function CheckoutFailed() {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
        <div className="text-center">
          <div className="mx-auto mb-4 h-16 w-16 rounded-full bg-red-100 flex items-center justify-center">
            <svg 
              className="h-8 w-8 text-red-600" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-red-600 mb-2">Checkout Failed</h2>
          <p className="text-gray-600 mb-6">We are sorry, but there was an issue processing your payment.</p>
        </div>

        <div className="space-y-4">
          <div className="space-y-3 mt-6">            
            <Link to="mailto:contact@spark-founders,com" className="block">
              <button className="w-full border border-gray-300 hover:bg-gray-50 py-2 px-4 rounded-md">
                Contact Support
              </button>
            </Link>
            
            <Link to="/" className="block">
              <button className="w-full text-gray-600 hover:text-gray-800 py-2 px-4">
                Return to homepage
              </button>
            </Link>
          </div>
        </div>

        <div className="mt-6 text-center text-sm text-gray-500">
          <p>Need help? Contact our support team at support@sparkfounders.com</p>
        </div>
      </div>
    </div>
  );
}

export default CheckoutFailed;