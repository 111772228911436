import React from "react";
import { EventItemDetails } from "./EventItemDetails";
import spark_thunder from "../../assets/icons/spark_thunder_primary.png";
import {
  CancelCircleIcon,
  InstagramIcon,
  Linkedin01Icon,
  Globe02Icon,
} from "hugeicons-react";

import sparkDefault from "../../assets/images/SiderMenu.png";
import { Link } from "react-router-dom";
import moment from "moment";
import { isoDateTimeToFormat } from "../../utils/helpers/date";

const ManageEvent = ({ userHasTicket, onManageClick, event }) => {
  const hasMatchesStarted =
    event?.matchAt != null && moment().isSameOrAfter(event?.matchAt);

  const hasEventFinished =
    event?.startAt != null && moment().isBefore(event?.endAt);

  return userHasTicket ? (
    <button className="p-4 bg-spark-bg-primary-light text-sm rounded-lg flex sm:flex-row flex-col justify-between sm:items-center w-full">
      <div className="flex items-center">
        <div className="bg-black w-8 h-8 flex items-center justify-center rounded-md min-w-8">
          <img width={12} src={spark_thunder} alt="spark" />
        </div>
        <div className="text-start ms-3">
          {hasMatchesStarted && event.userGuestStatus === "check_in" ? (
            <>
              <span className="font-semibold">It's Time!</span>
              <p className="text-xs">View your matches now</p>
            </>
          ) : event.userGuestStatus === "check_in" ? (
            <>
              <span className="font-semibold">You're in the event</span>
              <p className="text-xs">
                Matches will be reveal at {isoDateTimeToFormat(event.matchAt)}
              </p>
            </>
          ) : (
            <>
              <span className="font-semibold">You're Going</span>
              <p className="text-xs">Enjoy the Event</p>
            </>
          )}
        </div>
      </div>
      {hasMatchesStarted && event.userGuestStatus === "check_in" ? (
        <Link
          to={`/events/${event.id}/matches`}
          className="sm:ms-1 mt-2 sm:mt-0 text-spark-primary text-xs"
        >
          Get my matches
        </Link>
      ) : event.userGuestStatus !== "check_in" ? (
        <Link
          to={`/ticket/${event.userGuestId}`}
          className="sm:ms-1 mt-2 sm:mt-0 text-spark-primary text-xs"
        >
          View my ticket
        </Link>
      ) : (
        <></>
      )}
    </button>
  ) : hasEventFinished ? (
    <button className="p-4 bg-spark-bg-primary-light text-sm rounded-lg flex sm:flex-row flex-col justify-between sm:items-center w-full">
      <div className="flex items-center">
        <div className="bg-black w-8 h-8 flex items-center justify-center rounded-md min-w-8">
          <img width={12} src={spark_thunder} alt="spark" />
        </div>
        <div className="text-start ms-3">
          <span className="font-semibold">You're Not Going</span>
          <p className="text-xs">We hope to see you next time!</p>
        </div>
      </div>
      <span
        onClick={() => {
          if (onManageClick) onManageClick(event);
        }}
        href="#"
        className="sm:ms-1 mt-2 sm:mt-0 text-black hover:underline text-xs"
      >
        Changed your mind? You can{" "}
        <span className="text-spark-primary">buy your ticket</span>
      </span>
    </button>
  ) : (
    <></>
  );
};

const EventView = ({
  event,
  containerClass,
  closeEventView,
  onManageClick,
}) => {
  if (!event) return <div>No event</div>;

  return (
    <div className={`p-6 bg-white ${containerClass}`}>
      <CancelCircleIcon
        onClick={closeEventView}
        className="w-5 h-5 absolute top-2 right-2"
      />
      {/* Header */}
      <div className="w-full flex justify-center">
        {event?.cover?.publicUrl ? (
          <img
            src={event?.cover?.publicUrl}
            alt="SPARK Founders Live"
            className="object-cover w-full rounded-lg aspect-[16/6]"
          />
        ) : (
          <div className="inset-0 rounded-lg bg-black flex items-center justify-center aspect-[16/6] w-full">
            <h2 className="text-2xl font-bold text-white">{event.title}</h2>
          </div>
        )}
      </div>

      {/* Event Details */}
      <EventItemDetails
        containerClass={"mt-6"}
        eventData={event}
        ManageEvent={ManageEvent}
        onManageClick={onManageClick}
      />

      {/* About Event */}
      <div className="mt-6 text-gray-700 mb-6">
        <h2 className="text-lg font-medium">About Event</h2>
        <p className="mt-2 text-sm leading-relaxed">{event?.description}</p>
        {event?.latitude && event?.longitude && (
          <iframe
            className="rounded-xl mt-6"
            title="location"
            width="100%"
            height="300"
            loading="lazy"
            src={`https://www.google.com/maps?q=${event?.latitude},${event.longitude}&z=12&output=embed`}
          ></iframe>
        )}
      </div>
      {event.hosts?.length && (
        <>
          <hr />
          <div className="w-full mt-6 mb-6">
            Hosted By:
            {event.hosts.map((host, i) => (
              <div key={i} className="flex mt-2 items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={`${host.picture?.publicUrl ?? sparkDefault}`}
                    alt={host.name}
                    className="aspect-[1/1] rounded-md w-10 h-10 me-2"
                  />
                  {host.name}
                </div>
                <div className="flex items-center gap-2">
                  {host.website && (
                    <a href={host.website}>
                      <Globe02Icon />
                    </a>
                  )}
                  {host.linkedln && (
                    <a href={host.linkedln}>
                      <Linkedin01Icon />
                    </a>
                  )}
                  {host.instagram && (
                    <a href={host.instagram}>
                      <InstagramIcon />
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {event.guests?.length > 0 && (
        <>
          <hr />
          <div className="w-full mt-6 mb-6">
            {event.guests?.length} going
            {
              <div className="flex items-center sm:mb-0 mb-3">
                <div className="inline-flex min-w-8 relative">
                  {event.guests
                    ?.slice(
                      0,
                      event.guests?.length > 5 ? 5 : event.guests?.length
                    )
                    .map((guest, i) => (
                      <img
                        key={i}
                        className={`-me-[14px] z-[${
                          6 - (i + 1)
                        }] w-8 h-8 rounded-full object-cover border`}
                        src={
                          guest?.user?.profilePicture?.publicUrl ?? sparkDefault
                        }
                        alt="Person"
                      />
                    ))}
                  {event.guests?.length > 5 && (
                    <div className="z-[1] -me-[5px] w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
                      +{event.guests.length - 5}
                    </div>
                  )}
                </div>
              </div>
            }
          </div>
        </>
      )}
    </div>
  );
};

export default EventView;
