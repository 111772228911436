import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import { api } from "../../Services/api-service";
import { isoDateTimeToFormat, timeZone } from "../../utils/helpers/date";
import { currencyFormat } from "../../utils/currency.util";

const TicketPage = () => {
  const [guest, setGuest] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { guestId } = useParams();

  useEffect(() => {
    const fetchGuest = async () => {
      try {
        const response = await api.get(`/events/guests/${guestId}`);
        setGuest(response.data.guest);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchGuest();
  }, [guestId]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#B30AF2] mx-auto mb-4"></div>
          <p className="text-gray-600">Loading ticket information...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="text-center">
          <p className="text-red-600 mb-4">Error loading ticket: {error}</p>
          <Link to="/my-tickets">
            <button className="bg-[#B30AF2] text-white px-4 py-2 rounded-md">
              Back to My Tickets
            </button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
        {/* Ticket Header */}
        <div className="text-center pb-4">
          <h2 className="text-2xl font-bold text-gray-800">
            {guest.event.title}
          </h2>
          <p className="text-gray-600">
            {isoDateTimeToFormat(guest.event.startAt)} •{" "}
            {isoDateTimeToFormat(guest.event.endAt)} {timeZone()}
          </p>
        </div>

        <div className="w-full flex justify-center border-b pb-4">
          <img
            src={guest.event?.cover?.publicUrl}
            alt="SPARK Founders Live"
            className="object-cover w-full rounded-lg aspect-[16/6]"
          />
        </div>

        {/* Ticket Details */}
        <div className="space-y-4 py-4">
          <div className="flex items-center justify-center mb-4">
            <QRCodeCanvas value={guest?.id || "default"} size={128} />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500">Ticket</p>
              <p className="font-medium">{guest?.ticket.name}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Ticket Price</p>
              <p className="font-medium">
                {currencyFormat(guest?.ticket.currency, guest?.ticket.pricce)}
              </p>
            </div>
          </div>
        </div>

        {guest.event.type === "online" ? (
          <div>
            <p className="text-sm text-gray-500">Address</p>
            <p className="font-medium">Online</p>
          </div>
        ) : (
          <div>
            <p className="text-sm text-gray-500">Address</p>
            <p className="font-medium">{guest?.event.address}</p>
          </div>
        )}

        {/* Actions */}
        <div className="space-y-3 mt-6 border-t pt-4">
          <button
            className="w-full bg-[#B30AF2] hover:bg-opacity-90 text-white py-2 px-4 rounded-md"
            onClick={() => window.print()}
          >
            Print Ticket
          </button>
          <Link to="/">
            <button className="w-full border border-gray-300 hover:bg-gray-50 py-2 px-4  mt-2 rounded-md">
              Back to Spark Founders
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TicketPage;
