import {
  InstagramIcon,
  Linkedin01Icon,
  PinLocation01Icon,
  Location01Icon,
  Globe02Icon,
} from "hugeicons-react";
import React from "react";
import sparkDefault from "../../../assets/images/SiderMenu.png";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../../utils/constans";
import { api } from "../../../Services/api-service";
import { currencyFormat } from "../../../utils/currency.util";

const Ticket = ({ eventData, ticket, closeModal }) => {
  const isoDateTimeToFormat = (date) => {
    if (!date) return "00:00";

    date = new Date(date);

    return `${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString(
      "en-US"
    )}`;
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    try {
      const stripePromise = await loadStripe(STRIPE_PUBLIC_KEY);

      const response = await api.post(`/events/guests/add`, {
        eventId: eventData?.id,
        ticketId: ticket?.id,
      });

      if (response.status) {
        closeModal();
      }

      if (!response?.data?.id) return;

      const session = await response.data?.id;
      stripePromise.redirectToCheckout({
        sessionId: session,
      });
    } catch (error) {
      console.error("Payment error:", error);
    }
  };

  return (
    <div className="mt-8">
      <div className="max-w-md w-full h-full mx-auto z-10 bg-spark-primary rounded-3xl">
        <div className="flex flex-col">
          <div className="bg-white relative rounded-3xl p-4 m-4">
            <div className="flex-none sm:flex">
              <div className="flex-auto justify-evenly">
                <div className="flex items-center justify-between">
                  <h2 className="font-semibold text-lg text-wrap">
                    {eventData?.title}
                  </h2>
                  <div className="ml-auto text-blue-800">{ticket?.name}</div>
                </div>
                <div className="text-sm text-gray-500 my-2">
                  {isoDateTimeToFormat(eventData?.startAt)} -{" "}
                  {isoDateTimeToFormat(eventData?.endAt)}
                </div>
                {eventData.type === "in_person" && (
                  <>
                    {eventData.address && (
                      <p className="text-xs flex items-center my-1">
                        <Location01Icon className="me-2" />
                        {eventData.address}
                      </p>
                    )}
                    {eventData?.latitude && (
                      <p className="text-xs flex items-center my-1">
                        <PinLocation01Icon className="me-2" />
                        {eventData.latitude},{eventData.longitude}
                      </p>
                    )}
                  </>
                )}

                {eventData.type === "online" && (
                  <p className="text-xs flex items-center my-1">
                    <Globe02Icon className="me-2" />
                    Online
                  </p>
                )}
                <div className="border-b border-dashed border-b-2 my-10">
                  <div className="absolute rounded-full w-5 h-5 bg-spark-primary -mt-2.5 -left-2.5"></div>
                  <div className="absolute rounded-full w-5 h-5 bg-spark-primary -mt-2.5 -right-2.5"></div>
                </div>
                {eventData?.hosts?.length > 0 && (
                  <>
                    <div className="w-full">
                      Hosted By:
                      {eventData?.hosts.map((host, i) => (
                        <div
                          key={i}
                          className="flex mt-2 items-center justify-between"
                        >
                          <div className="flex items-center">
                            <img
                              src={`${host.picture?.publicUrl ?? sparkDefault}`}
                              alt={host.name}
                              className="aspect-[1/1] rounded-md w-10 h-10 me-2"
                            />
                            {host.name}
                          </div>
                          <div className="flex items-center gap-2">
                            {host.website && (
                              <a href={host.website}>
                                <Globe02Icon />
                              </a>
                            )}
                            {host.linkedln && (
                              <a href={host.linkedln}>
                                <Linkedin01Icon />
                              </a>
                            )}
                            {host.instagram && (
                              <a href={host.instagram}>
                                <InstagramIcon />
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div className="border-b border-dashed border-b-2 my-10">
                  <div className="absolute rounded-full w-5 h-5 bg-spark-primary -mt-2.5 -left-2.5"></div>
                  <div className="absolute rounded-full w-5 h-5 bg-spark-primary -mt-2.5 -right-2.5"></div>
                </div>
                <div className="flex items-center justify-between mb-4 space-x-2">
                  <div className="flex flex-col text-sm flex-1">
                    <span>Description</span>
                    <div className="font-semibold break-all">
                      {ticket?.description}
                    </div>
                  </div>
                  {eventData.type !== "online" && (
                    <div className="flex flex-col text-sm border-s-2 ps-2 flex-1 items-end">
                      <div className="text-start">
                        <span>Access</span>
                        <div className="font-semibold">{ticket?.access}</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="border-b border-dashed border-b-2 my-10">
                  <div className="absolute rounded-full w-5 h-5 bg-spark-primary -mt-2.5 -left-2.5"></div>
                  <div className="absolute rounded-full w-5 h-5 bg-spark-primary -mt-2.5 -right-2.5"></div>
                </div>
                <button
                  className="mt-4 bg-spark-primary text-white rounded-lg py-5 px-4 w-full"
                  onClick={(e) => {
                    handlePayment(e);
                  }}
                >
                  Purchase For {currencyFormat(ticket?.currency, ticket?.price)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
