/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import authSvc from "../../Services/auth-service";
import { Country, State, City } from "country-state-city";
import { VscDeviceCamera } from "react-icons/vsc";
import "react-country-state-city/dist/react-country-state-city.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchAttributes } from "../../redux/attributeSlice";
import Select from "react-select";
import { FaRegEdit } from "react-icons/fa";

const Update = () => {
  const [formData, setFormData] = useState({
    personalDetails: {
      firstName: "",
      lastName: "",
      email: "",
      tagline: "",
      country: null,
      state: null,
      city: "",
    },
    profile: {
      role: "",
      lookingFor: "",
      businessStage: "",
      skills: [],
      interests: [],
      description: "",
    },
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const [preview, setPreview] = useState(null);

  const [countryid, setCountryid] = useState(0);

  const [stateid, setstateid] = useState(0);

  const dispatch = useDispatch();

  const { lookingFor, skill, interest, role } = useSelector(
    (state) => state.attributes || {}
  );

  const navigate = useNavigate();

  const viewProfile = async () => {
    const response = await api.get("/users/view");
    if (response && response.status === 200) {
      const user = response.data.user;

      setPreview(response.data.user.profilePicture.publicUrl);

      setFormData({
        personalDetails: {
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email || "",
          tagline: user.tagline || "",
          country: user.country || null,
          state: user.state || null,
          city: user.city || "",
        },
        profile: {
          role: user.profile.role || "",
          lookingFor: user.profile.lookingFor || "",
          businessStage: user.profile.businessStage || "",
          skills: user.profile.skills || [],
          interests: user.profile.interests || [],
          description: user.profile.description || "",
        },
      });

      setCountryid(user.country ? user.country.id : 0);
      setstateid(user.state ? user.state.id : 0);
    }
  };

  useEffect(() => {
    viewProfile();
  }, []);

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
    }
  }, [selectedFile]);

  const updateProfile = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      updateProfilePicture(e);
    }
    const response = await api.put("users/profiles/update", formData);
    if (response && response.status === 200) {
      notificationSvc.success("Profile Updated Successfully.");
      authSvc.refresh();
    }
  };

  const updateProfilePicture = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      if (selectedFile) {
        formData.append("profileImage", selectedFile);
      }
      const response = await api.patch("/users/profile-picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response && response.status === 200) {
        notificationSvc.success(response.data.message);
        authSvc.refresh();
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const allStates = (countryName) => {
    const country = Country.getAllCountries().find(
      (country) => country.name === countryName
    );
    if (country) {
      return State.getStatesOfCountry(country.isoCode).map((state) => ({
        value: state.name,
        label: state.name,
      }));
    }
    return [];
  };

  const allCities = (countryName, stateName) => {
    const country = Country.getAllCountries().find(
      (country) => country.name === countryName
    );
    if (country) {
      const state = State.getStatesOfCountry(country.isoCode).find(
        (state) => state.name === stateName
      );
      if (state) {
        return City.getCitiesOfState(country.isoCode, state.isoCode).map(
          (city) => ({
            value: city.name,
            label: city.name,
          })
        );
      }
    }
    return [];
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#3b82f6" : "#d1d5db",
      boxShadow: state.isFocused ? "0 0 0 2px #3b82f6" : "none",
      "&:hover": {
        borderColor: "#3b82f6",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#e0f2fe" : "white",
      color: "#111827",
    }),
  };

  return (
    <div className="">
      <form
        className="my-12"
        onSubmit={(e) => {
          updateProfile(e);
        }}
      >
        <div className="flex flex-col sm:flex-row gap-6 items-center">
          <div className="relative w-24 h-24 sm:w-40 sm:h-40 bg-slate-200/50">
            {preview && (
              <img
                src={preview}
                alt=""
                className="w-full h-full object-cover border-4 border-spark-700 rounded-md"
              />
            )}
            <div className="absolute -right-1.5 -bottom-2 sm:-right-2.5 sm:-bottom-3">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center p-2 rounded-xl cursor-pointer bg-purple-50"
              >
                <VscDeviceCamera color="#b30af2" size={25} />
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                  accept="image/*"
                />
              </label>
            </div>
          </div>
          {/* <div className="flex items-center sm:items-end mt-4 sm:mt-0">
            <button
              onClick={(e) => {
                updateProfilePicture(e);
              }}
              className="flex items-center gap-3 w-full sm:w-auto px-6 py-3 text-white bg-gray-500 rounded-lg"
            >
              <FaRegEdit />
              Upload Image
            </button>
          </div> */}
        </div>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              First name
            </label>
            <div className="mt-2">
              <input
                value={formData.personalDetails.firstName}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    personalDetails: {
                      ...prev.personalDetails,
                      firstName: e.target.value,
                    },
                  }));
                }}
                className="block w-full rounded-md border border-gray-300 py-1.5 text-gray-900"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Last name
            </label>
            <div className="mt-2">
              <input
                type="text"
                value={formData.personalDetails.lastName}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    personalDetails: {
                      ...prev.personalDetails,
                      lastName: e.target.value,
                    },
                  }));
                }}
                className="block w-full rounded-md border-gray-300 py-1.5 text-slate-900"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Email
            </label>
            <div className="mt-2">
              <input
                type="email"
                value={formData.personalDetails.email}
                disabled
                className="block w-full border-gray-300 rounded-md py-1.5 text-slate-900"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Country
            </label>
            <div className="mt-2">
              <div className="mt-2">
                <Select
                  styles={customSelectStyles}
                  value={
                    formData.personalDetails.country
                      ? {
                          value: formData.personalDetails.country,
                          label: formData.personalDetails.country,
                        }
                      : null
                  }
                  options={Country.getAllCountries().map((country) => ({
                    value: country.name,
                    label: country.name,
                  }))}
                  onChange={(selectedOption) => {
                    setFormData((prev) => ({
                      ...prev,
                      personalDetails: {
                        ...prev.personalDetails,
                        country: selectedOption.value,
                        state: null,
                        city: "",
                      },
                    }));
                  }}
                  placeholder="Select Country"
                />
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              State
            </label>
            <div className="mt-2">
              <Select
                styles={customSelectStyles}
                value={
                  formData.personalDetails.state
                    ? {
                        value: formData.personalDetails.state,
                        label: formData.personalDetails.state,
                      }
                    : null
                }
                options={allStates(formData.personalDetails.country)}
                onChange={(selectedOption) => {
                  setFormData((prev) => ({
                    ...prev,
                    personalDetails: {
                      ...prev.personalDetails,
                      state: selectedOption.value,
                      city: "",
                    },
                  }));
                }}
                placeholder="Select State"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              City
            </label>
            <div className="mt-2">
              <Select
                styles={customSelectStyles}
                value={
                  formData.personalDetails.city
                    ? {
                        value: formData.personalDetails.city,
                        label: formData.personalDetails.city,
                      }
                    : null
                }
                options={allCities(
                  formData.personalDetails.country,
                  formData.personalDetails.state
                )}
                onChange={(selectedOption) => {
                  setFormData((prev) => ({
                    ...prev,
                    personalDetails: {
                      ...prev.personalDetails,
                      city: selectedOption.value,
                    },
                  }));
                }}
                placeholder="Select City"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Role
            </label>
            <div className="mt-2">
              <Select
                styles={customSelectStyles}
                value={role
                  .map((role) => ({ value: role, label: role }))
                  .find((option) => option.value === formData.profile.role)}
                options={role.map((roleItem) => ({
                  value: roleItem,
                  label: roleItem,
                }))}
                onChange={(selectedOption) => {
                  setFormData((prev) => ({
                    ...prev,
                    profile: {
                      ...prev.profile,
                      role: selectedOption.value,
                    },
                  }));
                }}
                placeholder="Select Role"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Looking For
            </label>
            <div className="mt-2">
              <Select
                styles={customSelectStyles}
                value={lookingFor
                  .map((looking) => ({ value: looking, label: looking }))
                  .find(
                    (option) => option.value === formData.profile.lookingFor
                  )}
                options={lookingFor.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(selectedOption) => {
                  setFormData((prev) => ({
                    ...prev,
                    profile: {
                      ...prev.profile,
                      lookingFor: selectedOption.value,
                    },
                  }));
                }}
                placeholder="Select What You're Looking For"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Skills
            </label>
            <div className="mt-2">
              <Select
                styles={customSelectStyles}
                value={skill
                  .map((skillItem) => ({
                    value: skillItem,
                    label: skillItem,
                  }))
                  .filter((option) =>
                    formData.profile.skills.includes(option.value)
                  )}
                options={skill.map((skillItem) => ({
                  value: skillItem,
                  label: skillItem,
                }))}
                onChange={(selectedOptions) => {
                  setFormData((prev) => ({
                    ...prev,
                    profile: {
                      ...prev.profile,
                      skills: selectedOptions.map((option) => option.value),
                    },
                  }));
                }}
                isMulti
                placeholder="Select Skills"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Interests
            </label>
            <div className="mt-2">
              <Select
                styles={customSelectStyles}
                value={interest
                  .map((interestItem) => ({
                    value: interestItem,
                    label: interestItem,
                  }))
                  .filter((option) =>
                    formData.profile.interests.includes(option.value)
                  )}
                options={interest.map((interestItem) => ({
                  value: interestItem,
                  label: interestItem,
                }))}
                onChange={(selectedOptions) => {
                  setFormData((prev) => ({
                    ...prev,
                    profile: {
                      ...prev.profile,
                      interests: selectedOptions.map((option) => option.value),
                    },
                  }));
                }}
                isMulti
                placeholder="Select Interests"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Description
            </label>
            <div className="mt-2">
              <textarea
                type="text"
                value={formData.profile.description}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    profile: {
                      ...prev.profile,
                      description: e.target.value,
                    },
                  }));
                }}
                className="block w-full border-gray-300 resize-none rounded-md py-1.5 text-slate-900"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block !font-lexendRegular text-slate-900">
              Tagline
            </label>
            <div className="mt-2">
              <textarea
                type="text"
                value={formData.personalDetails.tagline}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    personalDetails: {
                      ...prev.personalDetails,
                      tagline: e.target.value,
                    },
                  }));
                }}
                className="block w-full border-gray-300 resize-none rounded-md py-1.5 text-slate-900"
              />
            </div>
          </div>
        </div>

        <div className="flex mt-10">
          <button
            type="submit"
            className="px-16 py-3 mx-auto bg-spark-700 text-white rounded-lg text-sm font-lexendBold shadow-sm hover:bg-spark-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-spark-600"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default Update;
