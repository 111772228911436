import { CheckmarkSquare03Icon } from "hugeicons-react";
import React from "react";

const Plan = ({
  plan,
  handlePayment,
  containerClass,
  planMeta,
  isActive,
}) => {
  return (
    <div
      className={`flex flex-col max-w-80 p-8 ${isActive && planMeta ? "text-white bg-spark-primary" : ""
        } ${planMeta && !isActive ? "border rounded-2xl" : "rounded-2xl"
        } ${containerClass}`}
    >
      <div className={`flex flex-col ${planMeta ? "items-center" : ""}`}>
        {planMeta && (
          <div
            className={`p-2 rounded-md ${isActive
                ? "bg-black text-white"
                : planMeta.iconBgClass + " text-spark-text-gray"
              }`}
          >
            <planMeta.Icon />
          </div>
        )}
        <h5 className={`mt-3 text-3xl`}>{plan.planName}</h5>
        {planMeta && (
          <div className="my-3">
            <span className="text-3xl font-bold">${plan.price}</span>
            <span className="text-xs ms-1">/{plan.billingCycle}</span>
          </div>
        )}
      </div>
      <div className="mt-1">
        {plan.Features?.map((feature) => {
          return (
            <div
              key={feature.featureId}
              className="flex mt-6 items-center gap-3"
            >
              <CheckmarkSquare03Icon />
              <div className="w-full">
                <h5 className="text-sm">{feature.featureName}</h5>
              </div>
            </div>
          );
        })}
      </div>
      {isActive ? (
        <span className="mt-4 bg-black text-center text-white rounded-lg py-3 px-4 w-full">
          Active Plan
        </span>
      ) : (
        <button
          className="mt-4 bg-spark-primary text-white rounded-lg py-3 px-4 w-full"
          onClick={(e) => {
            handlePayment(e, plan.planId);
          }}
        >
          Choose Plan
        </button>
      )}
    </div>
  );
};

export default Plan;
