import { ArrowRight02Icon } from "hugeicons-react";
import { EventItemDetails } from "./EventItemDetails";
import sparkDefault from "../../assets/images/SiderMenu.png";

const EventItem = ({
  event,
  containerClass,
  eventContainerClass,
  showViewClick = null,
  onViewClick = null,
  isActive,
  onManageClick,
  onTicketClick,
  onConnectClick,
}) => {
  return (
    <div className={`${containerClass}`}>
      <div
        className={`border-s-2 ${
          event.userHasTicket
            ? "border-spark-primary"
            : "border-spark-primary-light"
        } p-4 flex justify-between md:items-center flex-col-reverse md:flex-row w-full ${
          eventContainerClass ?? ""
        }`}
      >
        <EventItemDetails
          onManageClick={onManageClick}
          onTicketClick={ onTicketClick }
          onConnectClick={ onConnectClick }
          eventData={event}
          eventGuests={event.guests}
        />
        <img
          src={event?.cover?.publicUrl ?? sparkDefault}
          alt={event.title}
          className="w-60 object-cover rounded-lg mb-3 md:mb-0"
        />
      </div>
      {showViewClick && (
        <div className="flex items-center">
          <button
            onClick={onViewClick}
            className={`flex items-center justify-center h-10 w-10 rounded-lg ms-1 ${
              !isActive
                ? "bg-spark-bg-primary-light text-spark-primary"
                : "bg-spark-primary text-white"
            }`}
          >
              <ArrowRight02Icon />

          </button>
        </div>
      )}
    </div>
  );
};

export default EventItem;
