import React, { useEffect, useState } from 'react';
import EventItem from './EventItem';

const GroupedEvent = ({ date, events, activeEvent, handleEventView, onManageClick, onTicketClick, onConnectClick }) => {
    const [formatedDate, setFormatedDate] = useState({});

    useEffect(() => {
        updateFormatedDate(date);
    }, [date]);

    const updateFormatedDate = (date) => {
        date = new Date(date);

        const month = date.toLocaleString('en-US', { month: 'short' });
        const day = date.toLocaleString('en-US', { weekday: 'long' });
        const dateNumber = date.getDate();

        setFormatedDate({
            month,
            date: dateNumber,
            day,
        });
    };
    return (
        <div className='mb-2 flex sm:flex-row flex-col'>
            <div className='w-16 me-2'>
                <div className='block text-xl text-nowrap font-bold'>
                    {formatedDate?.month} {formatedDate?.date}
                </div>
                <span className='text-xs text-spark-text-gray'>{formatedDate.day}</span>
            </div>
            <div className='flex-1'>
                {events?.map((event, i) => {
                    return (
                        <EventItem
                            key={i}
                            showViewClick={true}
                            containerClass={'mb-2 flex flex-row justify-center space-x-5 w-full'}
                            event={event}
                            isActive={i === activeEvent?.activeIndex && date === activeEvent?.date}
                            onViewClick={() => handleEventView(date, i)}
                            onManageClick={onManageClick}
                            onTicketClick={onTicketClick}
                            onConnectClick={onConnectClick}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default GroupedEvent;
