export const currencyName = (currency) => {
  return new Intl.DisplayNames(["en"], { type: "currency" }).of(
    currency.toUpperCase()
  );
};

export const currencyFormat = (currency, amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
  }).format(amount);
};