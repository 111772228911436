import React from 'react';

export const Modal = ({ isOpen, onRequestClose, children }) => {
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center z-[1010] backdrop-blur-sm h-screen w-full">
      <div className="fixed inset-0 bg-black/50" onClick={onRequestClose} />
      <div className="relative z-50">{children}</div>
    </div>
  );
};