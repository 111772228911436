import { CancelCircleIcon } from "hugeicons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import Select, { components } from "react-select";

import { GetCountries, GetState, GetCity } from "react-country-state-city";

const CustomMultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CancelCircleIcon className="w-4 h-4" />
    </components.MultiValueRemove>
  );
};

const FilterModal = ({
  isModalOpen,
  setIsModalOpen,
  onModalClose,
  filters,
  setFilters,
  onApplyClick,
}) => {
  const { skill, interest, lookingFor } = useSelector(
    (state) => state.attributes
  );
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountryId] = useState(null);

  useEffect(() => {
    setCountriesState();
  }, []);

  const setCountriesState = async () => {
    const countries = await GetCountries();

    setCountries(countries);
  };

  const updateStates = async (calName) => {
    const cId = countries.find((c) => c.name === calName)?.id;
    const states = await GetState(cId);

    setSelectedCountryId(cId);
    setStates(states);
  };

  const updateCities = async (sName) => {
    const sId = states.find((s) => s.name === sName)?.id;
    const cities = await GetCity(selectedCountry, sId);

    setCities(cities);
  };

  const hasActiveFilter = () => {
    return Object.values(filters).some((filter) => {
      if (Array.isArray(filter)) return filter.length > 0;
      if (typeof filter === "object" && filter !== null) {
        return filter.from || filter.to;
      }
      return !!filter;
    });
  };

  const resetFilters = () => {
    const resetState = {
      country: "",
      state: "",
      city: "",
      interests: null,
      skills: null,
      lookingFor: null,
      scoreRange: null,
    };
    setFilters(resetState);
    onApplyClick(resetState);
    setIsModalOpen(false);
  };

  return (
    <Modal
      classNames={{
        root: "!z-[1002]",
        overlay: "backdrop-blur-sm h-screen w-full",
        modalContainer: "!fixed right-0 !bottom-0",
        modal: "!m-0 rounded-xl w-full sm:w-96 !mt-5 h-full max-h-[calc(100vh-20px)]",
        closeButton: "!top-[20px]",
      }}
      open={isModalOpen}
      onClose={onModalClose}
      styles={{ zIndex: "1003" }}
      closeIcon={<CancelCircleIcon />}
    >
      <form className="flex flex-col h-full justify-between max-h-[calc(100vh-58.4px)]">
        <div>
          <p className="text-xl">Filters</p>
          <hr className="my-4" />
        </div>
        <div className="overflow-scroll mb-6">
          <div className="mb-2">
            <p className="p-1">Country</p>
            <Select
              value={{ label: filters?.country, value: filters?.country }}
              isMulti={false}
              isClearable={true}
              classNames={{
                menu: () =>
                  "!relative m-3 border-t !rounded-t-none !w-[calc(100%-1.5rem)] !shadow-none",
                control: () => "!border-0 !shadow-none !py-2",
                container: () => "!border border-1 rounded-md",
                option: () =>
                  "hover:!bg-spark-bg-primary-light !bg-white !text-black",
                input: () => "[&>input:focus]:!ring-0",
              }}
              placeholder="Select Country"
              options={countries.map((c) => ({
                label: c.name,
                value: c.name,
              }))}
              onChange={(e) => {
                setFilters({ ...filters, country: e?.value ?? null });
                updateStates(e?.value);
              }}
              components={{ MultiValueRemove: CustomMultiValueRemove }}
            />
          </div>
          <div className="mb-2">
            <p className="p-1">State</p>
            <Select
              value={{ label: filters?.state, value: filters?.state }}
              isMulti={false}
              isClearable={true}
              classNames={{
                menu: () =>
                  "!relative m-3 border-t !rounded-t-none !w-[calc(100%-1.5rem)] !shadow-none",
                control: () => "!border-0 !shadow-none !py-2",
                container: () => "!border border-1 rounded-md",
                option: () =>
                  "hover:!bg-spark-bg-primary-light !bg-white !text-black",
                input: () => "[&>input:focus]:!ring-0",
              }}
              placeholder="Select State"
              options={states.map((c) => ({
                label: c.name,
                value: c.name,
              }))}
              isDisabled={!filters?.country}
              onChange={(e) => {
                setFilters({ ...filters, state: e?.value ?? null });
                updateCities(e?.value);
              }}
              components={{ MultiValueRemove: CustomMultiValueRemove }}
            />
          </div>
          <div className="mb-2">
            <p className="p-1">City</p>
            <Select
              value={{ label: filters?.city, value: filters?.city }}
              isMulti={false}
              isClearable={true}
              classNames={{
                menu: () =>
                  "!relative m-3 border-t !rounded-t-none !w-[calc(100%-1.5rem)] !shadow-none",
                control: () => "!border-0 !shadow-none !py-2",
                container: () => "!border border-1 rounded-md",
                option: () =>
                  "hover:!bg-spark-bg-primary-light !bg-white !text-black",
                input: () => "[&>input:focus]:!ring-0",
              }}
              isDisabled={!filters?.state}
              placeholder="Select City"
              options={cities.map((c) => ({
                label: c.name,
                value: c.name,
              }))}
              onChange={(e) => {
                setFilters({ ...filters, city: e?.value ?? null });
              }}
              components={{ MultiValueRemove: CustomMultiValueRemove }}
            />
          </div>
          <div className="mb-2">
            <p className="p-1">Skills</p>
            <Select
              value={filters?.skills?.map((s) => ({ label: s, value: s }))}
              isMulti={true}
              classNames={{
                multiValue: () => "!bg-transparent border border-1 !rounded-md",
                multiValueLabel: () => "!text-spark-text-gray",
                menu: () =>
                  "!relative m-3 border-t !rounded-t-none !w-[calc(100%-1.5rem)] !shadow-none",
                control: () => "!border-0 !shadow-none !py-2",
                container: () => "!border border-1 rounded-md",
                option: () => "hover:!bg-spark-bg-primary-light !bg-white",
                input: () => "[&>input:focus]:!ring-0",
              }}
              placeholder="Select Skills"
              options={skill?.map((s) => ({ value: s, label: s }))}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  skills: e?.map((s) => s.value) ?? null,
                });
              }}
              components={{ MultiValueRemove: CustomMultiValueRemove }}
            />
          </div>
          <div className="mb-2">
            <p className="p-1">Interests</p>
            <Select
              value={filters?.interests?.map((i) => ({ label: i, value: i }))}
              isMulti={true}
              classNames={{
                multiValue: () => "!bg-transparent border border-1 !rounded-md",
                multiValueLabel: () => "!text-spark-text-gray",
                menu: () =>
                  "!relative m-3 border-t !rounded-t-none !w-[calc(100%-1.5rem)] !shadow-none",
                control: () => "!border-0 !shadow-none !py-2",
                container: () => "!border border-1 rounded-md",
                option: () => "hover:!bg-spark-bg-primary-light !bg-white",
                input: () => "[&>input:focus]:!ring-0",
              }}
              placeholder="Select Interests"
              options={interest?.map((s) => ({ value: s, label: s }))}
              components={{ MultiValueRemove: CustomMultiValueRemove }}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  interests: e?.map((i) => i.value) ?? null,
                });
              }}
            />
          </div>
          <div className="mb-2">
            <p className="p-1">Looking For</p>
            <Select
              value={{ label: filters?.lookingFor, value: filters?.lookingFor }}
              isMulti={false}
              isClearable={true}
              classNames={{
                menu: () =>
                  "!relative m-3 border-t !rounded-t-none !w-[calc(100%-1.5rem)] !shadow-none",
                control: () => "!border-0 !shadow-none !py-2",
                container: () => "!border border-1 rounded-md",
                option: () =>
                  "hover:!bg-spark-bg-primary-light !bg-white !text-black",
                input: () => "[&>input:focus]:!ring-0",
              }}
              placeholder="Select Looking For"
              options={lookingFor?.map((l) => ({ label: l, value: l }))}
              onChange={(e) => {
                setFilters({ ...filters, lookingFor: e?.value ?? null });
              }}
              components={{ MultiValueRemove: CustomMultiValueRemove }}
            />
          </div>
          <div>
            <p className="p-1">Score Range</p>
            <div className="grid grid-cols-2 gap-1 mt-2">
              <div className="flex items-center space-x-2">
                <label htmlFor="rangeFrom">From</label>
                <input
                  value={filters?.scoreRange?.from}
                  placeholder="00"
                  className="w-full rounded-xl border-spark-text-gray/[33%]"
                  step={1}
                  min={0}
                  max={100}
                  type="number"
                  name="fromScore"
                  id="fromScore"
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      scoreRange: {
                        ...filters.scoreRange,
                        from: e.target.value ?? null,
                      },
                    });
                  }}
                />
              </div>
              <div className="flex items-center space-x-2">
                <label htmlFor="rangeFrom">To</label>
                <input
                  value={filters?.scoreRange?.to}
                  placeholder="100"
                  className="w-full rounded-xl border-spark-text-gray/[33%]"
                  step={1}
                  min={0}
                  max={100}
                  type="number"
                  name="toScore"
                  id="toScore"
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      scoreRange: {
                        ...filters.scoreRange,
                        to: e.target.value ?? null,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-1 mt-auto">
          <button
            onClick={(e) => {
              e.preventDefault();
              if (hasActiveFilter()) {
                resetFilters();
              } else {
                setIsModalOpen(false);
              }
            }}
            className="flex-1 rounded-s-md bg-spark-bg-primary-light p-3"
          >
            {hasActiveFilter() ? "Reset" : "Cancel"}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              onApplyClick();
              setIsModalOpen(false);
            }}
            className="flex-1 rounded-e-md text-white bg-spark-700 p-3"
          >
            Apply
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default FilterModal;
