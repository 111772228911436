import React from "react";
import { Link } from "react-router-dom";

function SuccessCheckout() {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
        <div className="text-center">
          <div className="mx-auto mb-4 h-16 w-16 rounded-full bg-green-100 flex items-center justify-center">
            <svg
              className="h-8 w-8 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-green-600 mb-2">
            Payment Successful!
          </h2>
          <p className="text-gray-600 mb-6">Thank you for your purchase.</p>
        </div>

        <div className="space-y-4">
          <div className="space-y-3 mt-6">
            <Link to="/" className="block">
              <button className="w-full border border-gray-300 hover:bg-gray-50 py-2 px-4 rounded-md">
                Return to Home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessCheckout;
