import React from "react";

const NoEventsMessage = () => {
  return (
    <div className="w-full h-full">
      <h1 className="text-center !font-lexendBold text-5xl">
        It looks like we don't have <br /> any events right now!
      </h1>
      <p className="mt-8 text-center font-lexendMedium text-2xl">
        Don't worry, we're working hard to bring exciting <br /> opportunities
        your way. Stay connected and <br />
        check back soon for updates.
      </p>
    </div>
  );
};

export default NoEventsMessage;
